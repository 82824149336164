<script>
import Horizontal from "./horizontal";
import ax from '../../helpers/restful/service';
import checkTestModal from '../../components/check-test.vue';
import showTestScoreModal from '../../components/show-test-score.vue';
import successModal from '../../components/success.vue';
import Receipt from '@/components/receipt.vue';
import {
    teacherMainLayoutComputed,
} from "@/state/helpers";

export default {
    components: {
        Horizontal,
        checkTestModal,
        showTestScoreModal,
        successModal,
        Receipt,
    },
    data() {
        return {
            uid: JSON.parse(localStorage.getItem('auth.currentUser')).uid || 0,
            currentUser: null,
            loaded: false,
            loaderHeight: window.innerHeight * 0.1,
            isShowCheckTestModal: false,
            isShowTestScoreModal: false,
            isShowSuccessModal: false,
            showReceiptModal: false,

            problems: [
                {
                    s: 1,
                    a: "ข้อใดคือ มาตรฐานวิชาชีพทางการศึกษา",
                    b: {
                        b1: "แนวทางประกอบวิชาชีพทางการศึกษาสำหรับผู้ที่ต้องการเป็นครู",
                        b2: "เกณฑ์และเงื่อนไขสำหรับการประกอบวิชาชีพทางการศึกษาสำหรับผู้เป็นครู",
                        b3: "ข้อกำหนดเกี่ยวกับคุณลักษณะและคุณภาพที่พึงประสงค์ในการประกอบวิชาชีพทางการศึกษา",
                        b4: "ตัวบ่งชี้และตัวชี้วัดเกี่ยวกับคุณสมบัติของผู้ประกอบวิชาชีพทางการศึกษา",
                    },
                    c: "b3",
                },
                {
                    s: 2,
                    a: "ข้อใดแสดงให้เห็นว่า ครูแพทย์แผนไทย เป็นนักวางแผนการเรียนการสอน",
                    b: {
                        b1: "มุ่งพัฒนาผู้เรียนให้เติบโตเต็มตามศักยภาพ",
                        b2: "ร่วมมือกับผู้อื่นในชุมชนอย่างสร้างสรรค์",
                        b3: "พัฒนาแผนการสอนให้สามารถปฏิบัติได้จริงในชั้นเรียน",
                        b4: "สร้างโอกาสให้ผู้เรียนทุกคนได้เรียนรู้ในทุกสถานการณ์",
                    },
                    c: "b3",
                },
                {
                    s: 3,
                    a: "กำหนดให้ในรายวิชาหนึ่งกำหนดหน่วยการเรียน 2(1-2-3) บอกอะไรกับครูแพทย์แผนไทย",
                    b: {
                        b1: "วิชา 2 หน่วยกิต สอนปฏิบัติ 1 ชั่วโมง บรรยาย 2 ชั่วโมง และสอนนอกเวลา 3 ชั่วโมง",
                        b2: "วิชา 2 หน่วยกิต สอนบรรยาย 1 ชั่วโมง สอนออนไลน์ 2 ชั่วโมง และสอนนอกเวลา 3 ชั่วโมง",
                        b3: "วิชา 2 หน่วยกิต สอนบรรยาย 1 ชั่วโมง สอนปฏิบัติในเวลา และนอกเวลา 2 ชั่วโมง และศึกษาด้วยตนเอง 3 ชั่วโมง",
                        b4: "วิชา 2 หน่วยกิต สอนบรรยาย 1 ชั่วโมง สอนปฏิบัติ 2 ชั่วโมง ศึกษาด้วยตนเองนอกเวลา 3 ชั่วโมง",
                    },
                    c: "b4",
                },
                {
                    s: 4,
                    a: "หากครูแพทย์แผนไทยต้องวางแผนการเรียนการสอน สำหรับผู้เรียนกลุ่มใหญ่ที่จำเป็นต้องให้ทำกิจกรรมกลุ่ม ข้อมูลของผู้เรียนใดต่อไปนี้ที่จำเป็นต้องรู้ก่อน เพื่อเตรียมการสอน",
                    b: {
                        b1: "เพศ",
                        b2: "อายุ",
                        b3: "จำนวน",
                        b4: "ความรู้เดิม",
                    },
                    c: "b3",
                },
                {
                    s: 5,
                    a: "สิ่งที่บ่งบอกครูแพทย์แผนไทยจะสอนอะไร สอนอย่างไร ให้กับผู้เรียนของตน คือข้อใด",
                    b: {
                        b1: "แผนการสอน",
                        b2: "ตารางสอน",
                        b3: "การวางกิจกรรม",
                        b4: "ประมวลรายวิชา",
                    },
                    c: "b1",
                },
                {
                    s: 6,
                    a: "ข้อใด เป็นการแสดงให้เห็นว่า ครูจันทนาครูแพทย์ไทย ได้จัดการกับเนื้อหาวิชาที่ตนเองรับผิดชอบ ได้ดีที่สุด",
                    b: {
                        b1: "รวมเนื้อหาที่แตกต่างกันเข้าเป็นหัวข้อเดียวกัน",
                        b2: "แยกเนื้อหาเป็นหน่วย หรือหัวข้อตามหลักการและทฤษฎี",
                        b3: "เขียนเนื้อหาที่ต้องสอน เรียงลำดับ แยกเป็นหัวข้อหลักและหัวข้อย่อย",
                        b4: "แบ่งเนื้อหาออกเป็น เนื้อหากลุ่มความรู้ เนื้อหากลุ่มทักษะ ความเชี่ยวชาญ และเนื้อหากลุ่มทัศนคติ",
                    },
                    c: "b3",
                },
                {
                    s: 7,
                    a: "กรณีครูแพทย์แผนไทยรวบรวมข้อมูลเกี่ยวกับผู้เรียน เช่น จำนวน เพศ แบบการเรียน หรือ แนวการคิด จัดอยู่ในขั้นตอนใดของการออกแบบการเรียนการสอน",
                    b: {
                        b1: "ขั้นออกแบบ",
                        b2: "ขั้นวิเคราะห์",
                        b3: "ขั้นสังเคราะห์",
                        b4: "ขั้นสำรวจ",
                    },
                    c: "b2",
                },
                {
                    s: 8,
                    a: "ข้อใดแสดงการเรียงลำดับระดับการเรียนรู้ ด้านพุทธิพิสัยตาม Bloom Taxonomy ได้ถูกต้อง",
                    b: {
                        b1: "ความเข้าใจ ความรู้ความจำ วิเคราะห์ การนำไปใช้ ประเมิน สร้างสรรค์",
                        b2: "ความรู้ความจำ  ความเข้าใจ  วิเคราะห์  ประเมิน  การนำไปใช้  สร้างสรรค์",
                        b3: "ความรู้ความจำ  การนำไปใช้ ความเข้าใจ ประเมิน วิเคราะห์ สร้างสรรค์",
                        b4: "ความรู้ความจำ  ความเข้าใจ การนำไปใช้ วิเคราะห์ ประเมิน สร้างสรรค์",
                    },
                    c: "b4",
                },
                {
                    s: 9,
                    a: "ถ้าครูอดิเรกรู้ว่าผู้เรียนแพทย์แผนไทยของตนส่วนใหญ่ ชอบคิดเป็นภาพ ชอบการเรียนที่มีรูปภาพ แผนที่ แผนภาพ ครูอดิเรกต้องจัดกิจกรรมอย่างไร จึงจะทำให้ผู้เรียนได้รับความรู้มากที่สุด",
                    b: {
                        b1: "แบ่งกลุ่มผู้เรียนให้เรียนจากแผนภาพ",
                        b2: "ให้ผู้เรียนเรียนรู้จากเกมส์ต่อภาพ",
                        b3: "ให้ผู้เรียนฟังบรรยายประกอบการนำเสนอด้วยภาพประกอบ",
                        b4: "ให้ผู้เรียนวาดภาพที่อธิบายเนื้อหาตามหัวข้อที่สอน",
                    },
                    c: "b3",
                },
                {
                    s: 10,
                    a: "การให้ผู้เรียนจดจำสูตรยาและให้เหตุผลการรักษาได้ จัดเป็นเนื้อหาประเภทใด",
                    b: {
                        b1: "พุทธิพิสัย",
                        b2: "จิตพิสัย",
                        b3: "ทักษะพิสัย",
                        b4: "ปัญญาพิสัย",
                    },
                    c: "b1",
                },
                {
                    s: 11,
                    a: "ในสภาพแวดล้อมการเรียนการสอนยุคดิจิทัล แม้ครูแพทย์แผนไทยจะจัดการเรียนการสอนในชั้นเรียน แต่สิ่งที่หลีกเลี่ยงไม่ได้มากที่สุด คือข้อใด",
                    b: {
                        b1: "การใช้ 200 M ในการประชุมออนไลน์กับผู้เรียน",
                        b2: "การใช้เครื่องมือออนไลน์สนับสนุนการสื่อสาร",
                        b3: "การฝึกปฏิบัติทักษะต่างๆ ผ่านการประชุมทางไกล",
                        b4: "การจัดสอบเพื่อวัดประเมินผลสัมฤทธิ์แบบออนไลน์",
                    },
                    c: "b2",
                },
                {
                    s: 12,
                    a: "ข้อใดคือลักษณะการเรียนการสอนแบบออนไลน์หรือ e-learning",
                    b: {
                        b1: "การเรียนรู้สำหรับทุกคน เรียนได้ตามกำหนดเวลา ทุกสถานที่",
                        b2: "การเรียนรู้จากสื่อมัลติมีเดีย เป็นกลุ่มร่วมกันในเวลาเดียวกัน",
                        b3: "การเรียนรู้ผ่านอินทราเน็ตที่ทุกคนแลกเปลี่ยนความรู้ระหว่างกันได้ตลอดเวลา",
                        b4: "การเรียนรู้ผ่านเครือข่ายคอมพิวเตอร์ ที่ผู้เรียนเรียนจากบทเรียนคอมพิวเตอร์ ตามความต้องการ",
                    },
                    c: "b4",
                },
                {
                    s: 13,
                    a: "ข้อใดเป็นเครื่องมือสำหรับให้ผู้เรียนใช้ เพื่อสร้างปฏิสัมพันธ์แบบไม่ประสานเวลา",
                    b: {
                        b1: "E-mail",
                        b2: "Voice call",
                        b3: "Video call",
                        b4: "Line",
                    },
                    c: "b1",
                },
                {
                    s: 14,
                    a: "ข้อใดอธิบายกลยุทธ์ หรือกลวิธีการเรียนการสอน ได้ดีที่สุด",
                    b: {
                        b1: "วิธีปฏิบัติตามหลักการของการเรียนการสอนที่ยึดตามธรรมเนียมปฏิบัติ เป็นขั้นตอนอย่างมีระบบ",
                        b2: "วิธีการเรียนการสอนที่ต้องใช้ศิลปะเฉพาะ เสริมขั้นตอนการเรียนการสอน ที่ช่วยให้วิธีนั้นมีคุณภาพและประสิทธิภาพมากขึ้น",
                        b3: "วิธีการเรียนการสอนที่ต้องใช้วิธีผลิกแพลงต่างๆ โดยอาศัยความรู้ความชำนาญ ทั้งของผู้เรียนและผู้สอนร่วมกัน",
                        b4: "วิธีการเรียนการสอนที่ออกแบบอย่างเหมาะสม กับผู้สอนและผู้เรียน ตามสภาพแวดล้อมที่ส่งผลทำให้ผู้สอนบรรลุเป้าหมายของการสอน",
                    },
                    c: "b3",
                },
                {
                    s: 15,
                    a: "ข้อใดเป็นวิธีการเรียนการสอนในชั้นเรียน ที่เน้นธรรมชาติของการมีส่วนร่วมของผู้เรียน มากที่สุด",
                    b: {
                        b1: "การสาธิต",
                        b2: "บทบาทสมมติ",
                        b3: "การอภิปราย",
                        b4: "การศึกษานอกสถานที่",
                    },
                    c: "b2",
                },
                {
                    s: 16,
                    a: "ในการสอนงาน ผู้สอนจำเป็นต้องทำอะไร เพื่อให้ผู้เรียนต้องรู้รายละเอียดของงานที่ตนเอง ต้องทำให้ได้",
                    b: {
                        b1: "อธิบาย",
                        b2: "บอก",
                        b3: "สาธิต",
                        b4: "ฝึกทำ",
                    },
                    c: "b1",
                },
                {
                    s: 17,
                    a: "การบอกผู้เรียนเกี่ยวกับหลักการหรือทฤษฎีที่เกี่ยวข้องกับงานที่ต้องปฏิบัติ จัดอยู่ในขั้นตอนใดของการสอนงาน",
                    b: {
                        b1: "อธิบาย",
                        b2: "บอก",
                        b3: "แสดง",
                        b4: "สาธิต",
                    },
                    c: "b1",
                },
                {
                    s: 18,
                    a: "การเรียนแบบร่วมมือ คือการให้ผู้เรียน เรียนรู้เป็นกลุ่มย่อย ลักษณะสำคัญของผู้เรียนในกลุ่มย่อยคือข้อใด",
                    b: {
                        b1: "มีความสนใจต่างกัน",
                        b2: "มีความสามารถแตกต่างกัน",
                        b3: "มีเป้าหมายของการเรียนต่างกัน",
                        b4: "มีทักษะการสื่อสารและปฏิสัมพันธ์ใกล้เคียงกัน",
                    },
                    c: "b2",
                },
                {
                    s: 19,
                    a: "ครูจันทนาจัดการเรียนแบบร่วมมือในวิชา การนวดไทย ครูจันทนาต้องการพัฒนาอะไรให้กับผู้เรียนมากที่สุด",
                    b: {
                        b1: "การเรียนรู้จากเพื่อนที่มีประสบการณ์ต่างกัน",
                        b2: "การเรียนรู้ด้วยการศึกษาข้อมูลจากแหล่งต่างๆ",
                        b3: "การเรียนรู้จากผลงานของแต่ละกลุ่มที่นำเสนอในชั้นเรียน",
                        b4: "การเรียนรู้จากการที่ผู้สอนและเพื่อนร่วมกันประเมินผลงานของแต่ละกลุ่ม",
                    },
                    c: "b1",
                },
                {
                    s: 20,
                    a: "ครูอดิเรก วางแผนใช้เวลาประมาณ 10 นาที ก่อนสอนเนื้อหาบทเรียน ในเวลา 10 นาทีนั้น ครูอดิเรก ควรทำอะไร",
                    b: {
                        b1: "ให้ผู้เรียนถามคำถาม",
                        b2: "บอกความสำคัญของเรื่องที่เรียน",
                        b3: "ชมเชย สนับสนุนและให้กำลังใจผู้เรียน",
                        b4: "แนะแนวทางการเรียนรู้ และส่งเสริมการจดจำ",
                    },
                    c: "b2",
                },
                {
                    s: 21,
                    a: "ข้อใดคือการประเมิน",
                    b: {
                        b1: "อุณหภูมิในร่างกายเท่ากับ 37 องศาเซลเซียส",
                        b2: "สุดาได้คะแนนวิชาภาษาไทย 20 คะแนน",
                        b3: "สมชายหนัก 80 กิโลกรัม",
                        b4: "นารีมีร่างกายที่ผอมเพรียว",
                    },
                    c: "b4",
                },
                {
                    s: 22,
                    a: "ประเภทของแบบสอบประเภทใดที่สามารถวัดความคิดสร้างสรรค์ได้",
                    b: {
                        b1: "แบบอัตนัยไม่จำกัดคำตอบ",
                        b2: "แบบตัวเลือก ถูกผิด",
                        b3: "เติมคำให้สมบูรณ์",
                        b4: "แบบตอบสั้น",
                    },
                    c: "b1",
                },
                {
                    s: 23,
                    a: "การรวบรวมข้อมูล หลักฐานต่าง ๆ เพื่อนำไปใช้ในการตัดสินใจ คือ สิ่งใด",
                    b: {
                        b1: "การวัด (measurement)",
                        b2: "การประเมิน (assessment)",
                        b3: "การประเมินผล (evaluation)",
                        b4: "ความตรง (validity)",
                    },
                    c: "b2",
                },
                {
                    s: 24,
                    a: "ข้อใด ไม่ใช่ หลักการของการวัดและประเมินการเรียนรู้",
                    b: {
                        b1: "ผู้เรียนเป็นผู้ตัดสินผลการเรียนรู้",
                        b2: "ความโปร่งใส/ตรวจสอบได้",
                        b3: "การนำไปใช้ประโยชน์",
                        b4: "ความยุติธรรม",
                    },
                    c: "b1",
                },
                {
                    s: 25,
                    a: "ค่าความเชื่อมั่น (Reliability) ในแบบทดสอบบอกให้ทราบว่า",
                    b: {
                        b1: "เครื่องมือหาค่าความสอดคล้องระหว่างข้อคำถาม",
                        b2: "เครื่องมือวัดได้ไม่คงที่ ไม่ว่าจะใช้วัดกี่ครั้งกับกลุ่มเดิมก็ตาม",
                        b3: "เครื่องมือหาค่าความยากง่ายพอเหมาะ",
                        b4: "เครื่องมือวัดได้คงที่ ไม่ว่าจะใช้วัดกี่ครั้งกับกลุ่มเดิมก็ตาม",
                    },
                    c: "b4",
                },
                {
                    s: 26,
                    a: "ข้อสอบที่มีอำนาจจำแนก คือข้อใด",
                    b: {
                        b1: "ข้อสอบผ่านการเก็บข้อมูลแล้ว",
                        b2: "ข้อสอบผ่านการวิเคราะห์ สังเคราะห์แล้ว",
                        b3: "ข้อสอบมีความยากง่ายพอกัน",
                        b4: "ข้อสอบสามารถแยกคนเก่ง ออกจากคนไม่เก่งได้",
                    },
                    c: "b4",
                },
                {
                    s: 27,
                    a: "ข้อใดคือความหมายของคะแนนจริง",
                    b: {
                        b1: "คะแนนที่เราไม่สามารถมองเห็น",
                        b2: "คะแนนที่เกิดความคลาดเคลื่อน",
                        b3: "คะแนนความสามารถของผู้สอบ",
                        b4: "คะแนนความสามารถของผู้สอบรวมกับคะแนนความคลาดเคลื่อน",
                    },
                    c: "b4",
                },
                {
                    s: 28,
                    a: "ข้อใดคือข้อจำกัดของข้อสอบแบบเลือกตอบ",
                    b: {
                        b1: "เปิดโอกาสให้ผู้ตอบเดาได้",
                        b2: "มีความเป็นปรนัยในการให้คะแนน",
                        b3: "ไม่เหมาะกับผู้สอบที่มีจำนวนมาก",
                        b4: "วัดความสามารถของสมองขั้นต่ำไปจนถึงซับซ้อน",
                    },
                    c: "b1",
                },
                {
                    s: 29,
                    a: "ข้อใดเป็นขั้นตอนแรกในการสร้างแบบสอบ",
                    b: {
                        b1: "สร้างแผนผังการสอบ",
                        b2: "วิเคราะห์หลักสูตร",
                        b3: "กำหนดจุดมุ่งหมายของการสอบ",
                        b4: "กำหนดรูปแบบของแบบสอบ",
                    },
                    c: "b3",
                },
                {
                    s: 30,
                    a: "ข้อใดกล่าวผิดเกี่ยวกับการใช้งานการประเมินแบบ rubic",
                    b: {
                        b1: "ใช้งานกับทดสอบสมรรถนะ",
                        b2: "ใช้งานได้ดีกับข้อสอบแบบตัวเลือก",
                        b3: "ใช้คู่กับแบบสังเกตพฤติกรรมการเรียน",
                        b4: "ใช้ประเมินความคิดขั้นสูง",
                    },
                    c: "b2",
                },
            ],
            answers: [
                {
                    s: 1,
                    b: ""
                },
                {
                    s: 2,
                    b: ""
                },
                {
                    s: 3,
                    b: ""
                },
                {
                    s: 4,
                    b: ""
                },
                {
                    s: 5,
                    b: ""
                },
                {
                    s: 6,
                    b: ""
                },
                {
                    s: 7,
                    b: ""
                },
                {
                    s: 8,
                    b: ""
                },
                {
                    s: 9,
                    b: ""
                },
                {
                    s: 10,
                    b: ""
                },
                {
                    s: 11,
                    b: ""
                },
                {
                    s: 12,
                    b: ""
                },
                {
                    s: 13,
                    b: ""
                },
                {
                    s: 14,
                    b: ""
                },
                {
                    s: 15,
                    b: ""
                },
                {
                    s: 16,
                    b: ""
                },
                {
                    s: 17,
                    b: ""
                },
                {
                    s: 18,
                    b: ""
                },
                {
                    s: 19,
                    b: ""
                },
                {
                    s: 20,
                    b: ""
                },
                {
                    s: 21,
                    b: ""
                },
                {
                    s: 22,
                    b: ""
                },
                {
                    s: 23,
                    b: ""
                },
                {
                    s: 24,
                    b: ""
                },
                {
                    s: 25,
                    b: ""
                },
                {
                    s: 26,
                    b: ""
                },
                {
                    s: 27,
                    b: ""
                },
                {
                    s: 28,
                    b: ""
                },
                {
                    s: 29,
                    b: ""
                },
                {
                    s: 30,
                    b: ""
                },
            ],

            index: 1,
            score: 0,
            pass_percent: 0.6, //60%

            way_to_get_document: "thaipost",
            payment: {
                token: process.env.VUE_APP_GB_TOKEN,
            },
        };
    },
    validations: {

    },
    computed: {
        ...teacherMainLayoutComputed,

    },
    mounted() {
        document.body.classList.add('authentication-bg');
        this.getUserInfo();

        this.videoPanelHeight = window.innerWidth * 9 / 16;

    },
    methods: {
        async getUserInfo() {
            console.log("GetUserInfo");
            console.log("UID:", this.uid);

            const resp = await ax.post("/get-latest-user-info", { uid: this.uid });
            console.log("resp:", resp);

            if (resp.status != 200) {
                return;
            }

            this.currentUser = resp.data;
            console.log("currentUser:", this.currentUser);
            this.loaded = true;
            console.log("loaded:", this.loaded);
        },
        closeCheckTestModal() {
            this.isShowCheckTestModal = false;
            return;
        },
        closeShowTestScoreModal() {
            this.isShowTestScoreModal = false;
            return;
        },
        increaseIndex() {
            this.index++;
        },
        decreaseIndex() {
            this.index--;
        },
        checkAnswers() {
            console.log("this.answers:", this.answers);
            const r = this.answers.every((e) => e.b != "");
            console.log("Answers all questions:", r);

            if (!r) {
                this.isShowCheckTestModal = true;
                return;
            }

            const score = this.problems.reduce((total, i) => {
                const [answer] = this.answers.filter(e => e.s == i.s);
                console.log("answer:", answer);
                console.log("total:", total);
                console.log("i.c:", i.c);
                console.log("answer.b:", answer.b);
                return i.c == answer.b ? total + 1 : total;
            }, 0);

            console.log("Score:", score);
            this.score = score;
            this.isShowTestScoreModal = true;
            return;
        },
        async saveScore() {
            this.isShowTestScoreModal = false;
            console.log("save");
            const r = await ax.post('/update-score', {
                uid: this.uid,
                score: this.score,
            });
            console.log("response:", r);
            this.isShowSuccessModal = true;
            window.location.reload();
            return;
        },
        async saveData() {
            const r = await ax.post('/update-score-way', {
                uid: this.uid,
                way_to_get_document: this.way_to_get_document,
            });
            console.log("response:", r);
            if (this.way_to_get_document == "thaipost") {
                this.payAgain();
                return;
            }
            this.isShowSuccessModal = true;
            return;
        },
        async payAgain() {
            const r = await ax.post('/course-test-pay-again', {
                uid: this.uid,
            });
            console.log("response:", r);
            const responseData = r.data;

            if (responseData.resultCode != "00") {
                const form = document.createElement("form");
                form.method = "POST";
                form.action = "https://api.gbprimepay.com/gbp/gateway/qrcode";
                form.enctype = "application/x-www-form-urlencoded";
                form.target = "_blank";

                const token = document.createElement("input");
                token.type = "hidden";
                token.value = this.payment.token;
                token.name = "token";
                form.appendChild(token);

                let amount = document.createElement("input");
                amount.type = "hidden";
                amount.value = "130";
                amount.name = "amount";
                form.appendChild(amount);

                let referenceNo = document.createElement("input");
                referenceNo.type = "hidden";
                referenceNo.value = responseData.referenceNo;
                referenceNo.name = "referenceNo";
                form.appendChild(referenceNo);

                let backgroundUrl = document.createElement("input");
                backgroundUrl.type = "hidden";
                backgroundUrl.value = responseData.backgroundUrl;
                backgroundUrl.name = "backgroundUrl";
                form.appendChild(backgroundUrl);

                document.body.appendChild(form);
                form.submit();
            }
            return;
        },
    },
};
</script>

<template>
    <div>
        <Horizontal :layout="layoutType">
            <div class="container" v-if="loaded && currentUser.teacher_score > 0">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h2 class="text-center">ท่านได้ทำการทดสอบเรียบร้อยแล้ว คะแนนของท่าน คือ</h2>
                                <h1 class="text-center">{{ currentUser.teacher_score }} / {{ problems.length }}</h1>

                                <hr class="my-3" />
                                <div v-if="currentUser.way_to_get_document == null">
                                    <b-input-group>
                                        <b-form-group label="เลือกวิธีการรับเอกสาร หนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทย"
                                            v-slot="">
                                            <b-form-radio v-model="way_to_get_document" value="thaipost"><span
                                                    class="ms-1"></span>จัดส่งทางไปรษณีย์ (ค่าธรรมเนียมการจัดส่ง 100 บาท
                                                และค่าดำเนินการ ค่าบริการทางอินเตอร์เนต 30 บาท)</b-form-radio>
                                            <b-form-radio v-model="way_to_get_document" value="self"><span
                                                    class="ms-1"></span>มารับด้วยตัวเอง</b-form-radio>
                                        </b-form-group>
                                    </b-input-group>
                                    <b-form @submit.prevent="saveData()">
                                        <div class="mt-3 text-end">
                                            <b-button type="submit" variant="success" class="w-sm">ดำเนินการต่อ</b-button>
                                        </div>
                                    </b-form>
                                </div>
                                <div
                                    v-else-if="currentUser.way_to_get_document == 'thaipost' && currentUser.course_test.resultCode != '00'">
                                    <b-form @submit.prevent="payAgain()">
                                        <div class="mt-3 text-end">
                                            <b-button type="submit" variant="success"
                                                class="w-sm">ชำระเงินอีกครั้ง</b-button>
                                        </div>
                                    </b-form>
                                </div>
                                <div class="text text-center" v-else-if="currentUser.way_to_get_document == 'self'">
                                    <h4>มารับเอกสารด้วยตนเองได้ที่</h4>
                                    <h4>
                                        สภาการแพทย์แผนไทย อาคาร 1 ชั้น 4<br />
                                        กรมการแพทย์แผนไทยและการแพทย์ทางเลือก<br />
                                        กระทรวงสาธารณสุข<br />
                                        ถ.ติวานนท์ ต.ตลาดขวัญ อ.เมือง จ.นนทบุรี 11000<br />

                                        ติดตามประกาศกำหนดการรับหนังสือสำคัญแสดงการเป็นครูแพทย์แผนไทย ได้ที่ <a
                                            href="https://thaimed.or.th" target="_blank">https://thaimed.or.th</a>
                                    </h4>
                                    <p>{{ currentUser.teacher_info.address }}</p>
                                </div>
                                <div class="text text-center" v-else-if="currentUser.way_to_get_document == 'thaipost' && currentUser.course_test.resultCode == '00'">
                                    <h4>ท่านได้ชำระค่าจัดส่งเรียบร้อยแล้ว</h4>
                                    <div class="mt-3 d-grid d-block">
                                        <b-button type="button" block size="xl" @click="showReceiptModal = !showReceiptModal"
                                            variant="primary">
                                            ใบเสร็จรับเงิน
                                        </b-button>
                                    </div>
                                    <hr class="my-3"/>
                                    <h4>
                                        ทางสภาการแพทย์แผนไทย<br/>
                                        จะทำการจัดส่งเอกสารไปตามที่อยู่ในระบบที่ระบุในตอนสมัครต่ออายุครูแพทย์แผนไทย
                                    </h4>
                                    <p>{{ currentUser.teacher_info.address }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-if="loaded && currentUser.teacher_score == null">
                <div class="row">
                    <div class="col-12">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="mb-3 text-center">แบบทดสอบ จำนวน {{ problems.length }} ข้อ</h4>

                                <h5 class="mb-3">
                                    ข้อ. {{ problems.filter((e) => e.s == index)[0].s }}<br />
                                    {{ problems.filter((e) => e.s == index)[0].a }} ?
                                </h5>

                                <b-input-group>
                                    <b-form-group v-slot="">
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b1"><span
                                                class="ms-1"></span>ก. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b1 }}</b-form-radio>
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b2"><span
                                                class="ms-1"></span>ข. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b2 }}</b-form-radio>
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b3"><span
                                                class="ms-1"></span>ค. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b3 }}</b-form-radio>
                                        <b-form-radio v-model="answers.filter((e) => e.s == index)[0].b" value="b4"><span
                                                class="ms-1"></span>ง. {{ problems.filter((e) => e.s ==
                                                    index)[0].b.b4 }}</b-form-radio>
                                    </b-form-group>

                                </b-input-group>
                            </div>
                            <div class="row my-3 card-body">
                                <div class="mt-2 col-12 col-md-6 col-lg-6 col-xl-6 d-grid">
                                    <b-button block size="lg" type="button" variant="primary" class="w-lg"
                                        :disabled="index <= 1" @click="decreaseIndex">ข้อก่อนหน้า</b-button>
                                </div>
                                <div class="mt-2 col-12 col-md-6 col-lg-6 col-xl-6 d-grid">
                                    <b-button block size="lg" type="button" variant="primary" class="w-lg"
                                        :disabled="index >= problems.length" @click="increaseIndex">ข้อต่อไป</b-button>
                                </div>
                            </div>
                            <div class="card-body text-center d-block">
                                <b-form @submit.prevent="checkAnswers()">
                                    <b-button size="lg" type="submit" variant="success" class="w-lg">ตรวจข้อสอบ</b-button>
                                </b-form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Horizontal>
        <successModal :isShow="isShowSuccessModal" v-on:close="isShowSuccessModal = false">
        </successModal>
        <checkTestModal :isShow="isShowCheckTestModal" v-on:close="closeCheckTestModal">
        </checkTestModal>
        <showTestScoreModal :isShow="isShowTestScoreModal" :score="score" :max_score="problems.length"
            :pass_percent="pass_percent" v-on:close="closeShowTestScoreModal" v-on:save="saveScore">
        </showTestScoreModal>

        <b-modal v-if="loaded && currentUser.course_test.resultCode == '00'" size="xl" v-model="showReceiptModal" no-close-on-esc
            no-close-on-backdrop hide-header-close centered>
            <Receipt v-if="currentUser.course_test != null" :receipt_no="currentUser.course_test.id"
                :reference_no="currentUser.course_test.referenceNo" :created_dt="currentUser.course_test.created_dt"
                :firstname="currentUser.teacher_info.firstname || '-'" :lastname="currentUser.teacher_info.lastname || '-'"
                :phone="currentUser.teacher_info.phone || '-'"
                :identity_no="currentUser.teacher_info.identity_number || '-'" receipt_name="ค่าดำเนินการจัดส่งเอกสาร"
                :amount="currentUser.course_test.amount"
                :fee="currentUser.course_test.fee ? currentUser.course_test.fee.toFixed(2) : currentUser.course_test.fee"
                :total="currentUser.course_test.amount && currentUser.course_test.fee ? (currentUser.course_test.amount + currentUser.course_test.fee).toFixed(2) : (currentUser.course_test.amount + currentUser.course_test.fee)">
                ></Receipt>
            <template #modal-footer>
                <div class="col-5 d-block d-grid">
                    <b-button variant="" size="xl" @click="showReceiptModal = !showReceiptModal;">
                        <h3 class="text-white">ปิด</h3>
                    </b-button>
                </div>
            </template>
    </b-modal>
</div></template>
