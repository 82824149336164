<script>
export default {
    props: {
        isShow: {
            type: Boolean,
            required: true,
        },
        score: {
            type: Number,
            required: true,
        },
        max_score: {
            type: Number,
            required: true,
        },
        pass_percent: {
            type: Number,
            required: true,
        }
    },
    components: {
    },
    data() {
        return {}
    },
}
</script>

<template>
    <b-modal size="md" v-model="isShow" no-close-on-esc no-close-on-backdrop hide-header-close>
        <h4 class="text-success text-center">คะแนนที่ได้รับ</h4>
        <h2 class="text-primary text-center">{{ score }} / {{ max_score }}</h2>

        <h2 class="text-info text-center">{{ score / max_score > pass_percent ? "ผ่านการทดสอบ" : "ไม่ผ่านการทดสอบ" }}</h2>

        <template #modal-footer>
            <div class="w-100 d-block d-grid" v-if="score / max_score > pass_percent">
                <b-button variant="success" size="xl" @click="$emit('save');">
                    ส่งคะแนน
                </b-button>
            </div>
            <div class="w-100 d-block d-grid">
                <b-button variant="warning" size="xl" @click="$emit('close');">
                    ตรวจทานข้อสอบใหม่อีกครั้ง
                </b-button>
            </div>
        </template>
    </b-modal>
</template>