<script>
import Layout from "../../layouts/teacher-course-test.vue";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "ระบบหลัก",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "ระบบหลัก",
      items: [
        {
          text: "Thaimed",
        },
        {
          text: "ข้อมูลส่วนตัว",
          active: true,
        },
      ],
    };
  },
  computed: {},
  mounted() {
    
  },
  methods: {
    
  },
};
</script>

<template>
  <div>
    <Layout>
      <PageHeader :title="title" :items="items" />
    </Layout>
  </div>
</template>