<script>

export default {
    props: {
        receipt_no: {
            type: [String, Number],
            required: true,
        },
        reference_no: {
            type: String,
            required: true,
        },
        created_dt: {
            type: String,
            required: true,
        },
        firstname: {
            type: String,
            default(e) {
                if(e == null){
                    return "-";
                }
                return e;
            }
        },
        lastname: {
            type: String,
            default(e) {
                if(e == null){
                    return "-";
                }
                return e;
            }
        },
        phone: {
            type: String,
            default(e) {
                if(e == null){
                    return "-";
                }
                return e;
            }
        },
        identity_no: {
            type: [String, Number],
            default(e) {
                if(e == null){
                    return "-";
                }
                return e;
            }
        },
        receipt_name: {
            type: String,
            required: true,
        },
        amount: {
            type: [String, Number],
            required: true,
        },
        fee: {
            type: [String, Number],
            required: true,
        },
        total: {
            type: [String, Number],
            required: true,
        },
    },
    data() {
        return {}
    },
    methods:{
        download(){
            
        }
    }
};
</script>
<style>
.invoice-box {
    max-width: 800px;
    margin: auto;
    padding: 30px;
    border: 1px solid #eee;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
    font-size: 16px;
    line-height: 24px;
    color: #555;
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    padding: 5px;
    vertical-align: top;
}

.invoice-box table tr td:nth-child(n + 2) {
    text-align: right;
}

.invoice-box table tr.top table td {
    padding-bottom: 20px;
}

.invoice-box table tr.top table td.title {
    font-size: 45px;
    line-height: 45px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}

.invoice-box table tr.item td {
    border-bottom: 1px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.item input {
    padding-left: 5px;
}

.invoice-box table tr.item td:first-child input {
    margin-left: -5px;
    width: 100%;
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

.invoice-box input[type="number"] {
    width: 60px;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }

    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}
</style>
<template>
    <div class="invoice-box">
        <table cellpadding="0" cellspacing="0">
            <tr class="top">
                <td colspan="4">
                    <table>
                        <tr>
                            <td class="title">
                                <img src="@/assets/images/thaimed-logo.png" style="width:100%; max-width:300px;">
                            </td>

                            <td>
                                ใบเสร็จรับเงิน เลขที่: {{ receipt_no }}<br>
                                รหัสอ้างอิง: {{ reference_no }}<br>
                                สร้างขึ้นเมื่อ: {{ created_dt }}
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr class="information">
                <td colspan="4">
                    <table>
                        <tr>
                            <td>
                                สำนักงานสภาการแพทย์แผนไทย<br />
                                กระทรวงสาธารณสุข อาคาร 1 ชั้น 4<br />
                                เลขที่ 88/23 ถนนติวานนท์ ตำบลตลาดขวัญ<br />
                                อำเภอเมือง จังหวัดนนทบุรี 11000<br />
                                เลขประจำตัวผู้เสียภาษี 0994000963602
                            </td>

                            <td class="text-end">
                                ชื่อ {{ firstname }}<br>
                                นามสกุล {{ lastname }}<br>
                                เบอร์โทรศัพท์ติดต่อ {{ phone }}<br/>
                                เลขประจำตัวประชาชน {{ identity_no }}<br/>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>

            <tr class="heading">
                <td colspan="3">รายการ</td>
                <td>ราคา</td>
            </tr>

            <tr class="details">
                <td colspan="3">{{ receipt_name || "-" }}</td>
                <td>{{ amount || 0 }}</td>
            </tr>
            <tr class="details">
                <td colspan="3">ค่าดำเนินการ และค่าบริการทางอินเตอร์เนต</td>
                <td>{{ fee || 0 }}</td>
            </tr>


            <tr class="total">
                <td colspan="3"></td>
                <td>รวมทั้งหมด : {{ total || 0 }} บาท</td>
            </tr>
        </table>
        <!-- <b-button variant="success" type="button" @click="download">
            <h4 class="text-white">ดาวน์โหลดใบเสร็จรับเงิน</h4>
        </b-button> -->
    </div>
</template>